import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import LandinPage from "./pages/LandinPage";
import HomeCare from "./pages/HomeCare";
import TreatmentPage from "./pages/TreatmentPage";
import NotFoundPage from "./pages/NotFoundPage";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandinPage />} />;
        <Route path="/home-care" element={<HomeCare />} />;
        <Route
          exact
          path="/treatment"
          element={<Navigate to="/#lp-treatments" />}
        />
        <Route path="/treatment/:name" element={<TreatmentPage />} />
        <Route path="*" element={<NotFoundPage />} />
        {/* Rota para ser ignorada pra pegar o sitemap */}
        <Route path="/sitemap-tsukamoto.xml" element={<></>} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
