import React from "react";
import quote from "../../images/icons/format-quote.svg";
import whats from "../../images/icons/whats.svg";
import "../../styles/homeCare/explain.css";

import marciaHomeCare from "../../images/pictures/marcia-card-home-care.webp";

import { whatsappLink } from "../../js/contact-info";

import dotsDivider from "../../images/icons/dot-divider.svg";

export default function Explain() {
  return (
    <>
      <img
        className="dots-divider"
        src={dotsDivider}
        alt="Divisor de conteúdo"
      />
      <div className="explain-container">
        <div className="img-container-explain">
          <img
            src={marciaHomeCare}
            alt="Atendimento personalizado"
            loading="lazy"
          />
        </div>
        <div className="content-container-explain">
          <img src={quote} alt="Quote" />
          <h1 className="title-explain">
            Atendimento personalizado e seguro na comodidade do seu lar.
          </h1>
          <div className="label-explain">
            <h2>Home Care</h2>
          </div>
          <p className="content-explain">
            Muitos de nós têm familiares ou entes queridos que vivem em
            instituições de cuidados assistidos ou estão acamados devido a
            doenças ou condições médicas, impossibilitando o acesso ao
            atendimento odontológico convencional. A Dra. Márcia Tsukamoto e sua
            equipe de dentistas especializados em Geriatria na Tsukamoto
            Odontologia oferecem cuidados direcionados especificamente para
            pacientes nessas circunstâncias.
          </p>
          <a
            href={whatsappLink}
            target="_blank"
            rel="noopener noreferrer"
            className="homeCare-button-homeCare"
          >
            <p className="homeCare-button-white-whats">Agendar por Whatsapp</p>
            <img src={whats} alt="Whatsapp" />
          </a>
        </div>
      </div>
    </>
  );
}
