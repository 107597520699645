import React from "react";
import Header from "../components/Header";
import whats from "../images/icons/whats.svg";
import star from "../images/icons/star.svg";

import { whatsappLink } from "../js/contact-info";

export default function Banner({ title, subtitle, img }) {
  return (
    <div
      className="banner-container"
      style={{
        background: `linear-gradient(54deg, #0018FF 0%, rgba(0, 24, 255, 0.00) 44.79%, rgba(0, 24, 255, 0.00) 64.58%, rgba(0, 24, 255, 0.24) 77.60%), url(${img}), lightgray 50% / cover no-repeat`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Header />
      <div className="slogan-container">
        <p className="subtitle">{subtitle}</p>
        <h3 className="title">{title}</h3>
        <a
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
          className="button-whats"
        >
          <p className="button-content">Agendar por Whatsapp</p>
          <img className="button-img" src={whats} alt="whats" />
        </a>
        <div className="rate-container">
          <img src={star} alt="star" />
          <img src={star} alt="star" />
          <img src={star} alt="star" />
          <img src={star} alt="star" />
          <img src={star} alt="star" />
        </div>
        <p className="rate-description">5/5 estrelas - Doctoralia</p>
      </div>
    </div>
  );
}
