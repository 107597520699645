import marcia from "../images/pictures/Marcia.webp";
import fabio from "../images/pictures/Fabio.webp";
import lucas from "../images/pictures/Lucas.webp";

const marciaReviews = {
  name: "Dra. Márcia Matsune Tuskamoto",
  img: marcia,
  where: "5/5 estrelas - Doctoralia",
  reviews: [
    {
      review:
        "Excelente profissional, sempre muito atenciosa e precisa nas explicações.",
      author: `Regina Vidal de Campos`,
      status: "Doctoralia",
      names: `Regina Vidal de Campos
      Doctoralia`,
    },
    {
      review:
        "Ótima profissional, consultório excepcional, sempre dentro do horário, atenciosa com os pacientes.",
      author: `Adriane`,
      status: "Doctoralia",
      names: `Adriane
      Doctoralia`,
    },
    {
      review:
        "Profissional competente que desempenha seu trabalho de maneira primorosa!! Manifesta cuidado tanto no que diz respeito ao tratamento como também no manuseio de seus materiais, dando tranquilidade durante o tratamento.",
      author: `Dejanira Crepaldi`,
      status: "Doctoralia",
      names: `Dejanira Crepaldi
      Doctoralia`,
    },
    {
      review:
        "Atendimento excelente, super atenciosa, explicou todas as minhas duvidas, ambiente agradável. Voltaria com certeza!",
      author: `Lucas Hitoshi`,
      status: "Doctoralia",
      names: `Lucas Hitoshi
      Doctoralia`,
    },
    {
      review:
        "Excelente profissional. Muito querida. Indico muito. Valor justo pelo tratamento. Excelente consultório.",
      author: `Cirlene Leite Silmann`,
      status: "Doctoralia",
      names: `Cirlene Leite Silmann
        Doctoralia`,
    },
    {
      review:
        "Eu já trato com a Dr Márcia há uns 5 anos e assim como ela, todos os outros profissionais que trabalham com ela são excelentes. Dr Márcia é educada, atenciosa e tem bastante conhecimento sobre sua profissão. Recomendo demais!",
      author: `Tatiana Araujo Amaral`,
      status: "Doctoralia",
      names: `Tatiana Araujo Amaral
        Doctoralia`,
    },
    {
      review:
        "Excelente profissional, tem paciência para lidar com criança. Minha filha tem 8 anos e foi muito bem atendida.",
      author: `Flavia Maria`,
      status: "Doctoralia",
      names: `Flavia Maria
        Doctoralia`,
    },
    {
      review:
        "Sou paciente da dr Márcia faz mais de 15 anos, minha família também Ela está de parabéns por ser uma excelente profissional.",
      author: `Leila A. moura`,
      status: "Doctoralia",
      names: `Leila A. moura
        Doctoralia`,
    },
  ],
};

const fabioReviews = {
  name: "Dr. Fábio Seiji Tsukamoto",
  img: fabio,
  where: "Cirurgião-Dentista CRO 31677",
  reviews: [
    {
      review:
        "Formado pela Universidade Federal do Paraná (UFPR), Especialista em Prótese Dentária, atuando principalmente em restaurações diretas com resinas compostas, clareamento dental, pino de fibra intra-radiculares e restaurações indiretas com cerâmicas odontológicas.",
      author: "",
      status: "",
      names: ``,
    },
  ],
};

const lucasReviews = {
  name: "Dr. Lucas Tsukamoto",
  img: lucas,
  where: "",
  reviews: [
    {
      review: "",
      author: "",
      status: "",
      names: ``,
    },
  ],
};

export { marciaReviews, fabioReviews, lucasReviews };
