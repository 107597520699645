import React, { useEffect } from "react";
import Banner from "../components/Banner";
import "../styles/Banner.css";
import Information from "../components/Information";
import Explain from "../components/homeCare/Explain";
import Phrase from "../components/homeCare/Phrase";
import ServiceModality from "../components/homeCare/ServiceModality";
import CareCard from "../components/homeCare/CareCard";
import SectionParagraph from "../components/homeCare/SectionParagraph";
import ReviewCard from "../components/ReviewCard";
import Location from "../components/Location";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

// import imgDefaultBackground from "../images/pictures/woman-smile-picture.webp";
import imgHomeCareBackground from "../images/pictures/home-care-banner.webp";

import { marciaReviews as REVIEWS } from "../js/reviews";

import { Helmet } from "react-helmet";

export default function HomeCare() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Atendimento Domiciliar</title>
        <meta name="description" content="Tsukamoto Atendimento Domiciliar" />
      </Helmet>
      <div className="background-header">
        <div className="banner-container">
          <Banner
            title="Atendimento Domiciliar"
            subtitle="SUA FAMÍLIA EM BOAS MÃOS"
            img={imgHomeCareBackground}
          />
        </div>
      </div>
      <Information />
      <Explain />
      <Phrase />
      <ServiceModality />
      <CareCard />
      <SectionParagraph />
      <ReviewCard
        img={REVIEWS.img}
        name={REVIEWS.name}
        where={REVIEWS.where}
        reviews={REVIEWS.reviews}
        reviewsNames={REVIEWS.reviews.names}
      />
      <Location />
      <Contact />
      <Footer />
    </div>
  );
}
