import React from "react";
import "../styles/Clients.css";

import clinic1 from "../images/clinic/Tsukamoto-Odontologia-Clinica-01.webp";
// import clinic2 from "../images/clinic/Tsukamoto-Odontologia-Clinica-02.webp";
import clinic3 from "../images/clinic/Tsukamoto-Odontologia-Clinica-03.webp";
import clinic4 from "../images/clinic/Tsukamoto-Odontologia-Clinica-04.webp";
import clinic5 from "../images/clinic/Tsukamoto-Odontologia-Clinica-05.webp";
import clinic6 from "../images/clinic/Tsukamoto-Odontologia-Clinica-06.webp";
import clinic7 from "../images/clinic/Tsukamoto-Odontologia-Clinica-07.webp";
import clinic8 from "../images/clinic/Tsukamoto-Odontologia-Clinica-08.webp";
import clinic9 from "../images/clinic/Tsukamoto-Odontologia-Clinica-09.webp";

// import Feedback from "./Feedback";
import ReviewCard from "./ReviewCard";

import next from "../images/icons/next.svg";
import prev from "../images/icons/prev.svg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { marciaReviews as REVIEWS } from "../js/reviews";

import dotsDivider from "../images/icons/dot-divider.svg";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  const arrowStyle = {
    ...style,
    position: "absolute",
    zIndex: "4",
    right: "5%",
    width: "50px",
    height: "50px",
    backgroundImage: `url(${next})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
  };
  return <div className={className} style={arrowStyle} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  const arrowStyle = {
    ...style,
    position: "absolute",
    zIndex: "4",
    left: "4.2%",
    width: "50px",
    height: "50px",
    backgroundImage: `url(${prev})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
  };
  return <div className={className} style={arrowStyle} onClick={onClick} />;
}

export default function Client() {
  const settingsImg = {
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const images = [
    {
      src: clinic1,
    },
    // {
    //   src: clinic2,
    // },
    {
      src: clinic3,
    },
    {
      src: clinic4,
    },
    {
      src: clinic5,
    },
    {
      src: clinic6,
    },
    {
      src: clinic7,
    },
    {
      src: clinic8,
    },
    {
      src: clinic9,
    },
  ];

  return (
    <div id="lp-client" className="main_client-container">
      <img
        className="dots-divider"
        src={dotsDivider}
        alt="Divisor de conteúdo"
      />
      <div className="client-container">
        <h3 className="container-card-review-title">Clientes Fiéis</h3>
        <p className="container-card-review-subtitle">
          Nossos clientes sempre retornam, saiba o motivo!
        </p>
      </div>
      <div className="background-clients">
        <div className="background-slider">
          <Slider {...settingsImg}>
            {images.map((image, index) => {
              const alt = "Imagem " + (Number(index) + 1) + " da clínica.";
              return (
                <img
                  className="clinic_img"
                  key={index}
                  src={image.src}
                  alt={alt}
                  loading="lazy"
                ></img>
              );
            })}
          </Slider>
          <div className="image-filter"></div>
        </div>
      </div>
      <ReviewCard
        img={REVIEWS.img}
        name={REVIEWS.name}
        where={REVIEWS.where}
        reviews={REVIEWS.reviews}
        reviewsNames={REVIEWS.reviews.names}
      />
    </div>
  );
}
