import React, { useEffect, useRef } from "react";
import L from "leaflet";

import "leaflet/dist/leaflet.css";

const MapContainer = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (!mapRef.current) {
        mapRef.current = L.map("map").setView(
          [-25.520538439610625, -49.23675833583505],
          13
        );

        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution:
            'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
        }).addTo(mapRef.current);

        L.marker([-25.520538439610625, -49.23675833583505]).addTo(
          mapRef.current
        );
      }
    }, 2000);
  }, []);
  return <div id="map" className="map-container" />;
};

export default MapContainer;
