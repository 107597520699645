import React from "react";
import familyPicture from "../images/pictures/family.webp";
import "../styles/Family.css";
import tsuka from "../images/logo/tsuka-white.svg";
import moto from "../images/logo/moto-white.svg";
import plus from "../images/logo/plus.svg";

import dotsDivider from "../images/icons/dot-divider.svg";

export default function Family() {
  return (
    <div id="lp-family" className="family-container">
      <img
        className="dots-divider"
        src={dotsDivider}
        alt="Divisor de conteúdo"
      />
      <div className="family-container__rectangle-bg"></div>
      <div className="family__content">
        <div>
          <h3 className="family-title">Família Tsukamoto</h3>
          <p className="family-subtitle">Sua família em boas mãos</p>
        </div>
        <div className="family-img-container">
          <img src={familyPicture} alt="familyPicture" loading="lazy" />
          <p>Família Tsukamoto: Fábio, Márcia, Hiro e Lucas Tsukamoto</p>
        </div>
        <div className="family-content-container">
          <h4>
            Mantendo viva a tradição familiar, com dedicação e compromisso
          </h4>
          <p>
            A Tsukamoto Odontologia é uma clínica familiar, fundada em 1997 pela
            Dra. Márcia Matsune Kazue Tsukamoto, com mais de 30 anos de
            experiência na área. Os filhos da Dra. Márcia, Lucas e Fábio
            Tsukamoto, também fazem parte da equipe, mantendo o legado familiar
            vivo.
          </p>
        </div>
        <div className="family-name">
          <img src={plus} alt="plus" />
          <div>
            <h4>Tsukamoto Odontologia</h4>
            <p>
              A clínica é reconhecida pela alta qualidade de seus serviços e
              pelo atendimento humanizado e especializado. Sua missão é promover
              a saúde e a estética bucal, sempre priorizando as decisões e o
              bem-estar dos pacientes. Além disso, a clínica oferece atendimento
              domiciliar e é especializada em odontogeriatria, atendendo às
              necessidades dos pacientes com restrições de mobilidade, bem como
              tratamento e acompanhamento específicos para pessoas idosas. A
              dedicação ao cliente, à saúde e a ampla experiência profissional
              refletem a confiança e a tradição que narram a história e atuação
              da Tsukamoto Odontologia.
            </p>
          </div>
        </div>
        <div className="family-name-meaning">
          <h3>Significado do Nome</h3>
        </div>
        <div className="family-japanese-name">
          <img src={tsuka} alt="tsuka" />
          <div className="family-japanese-name-meaning">
            <h4>Colina</h4>
            <p>
              No Kanji, um dos sistemas de escrita do japonês, um mesmo
              ideograma pode conter uma ampla variedade de sons e significados.
              A palavra "塚" (tsuka) pode ser traduzida como monte ou colina.
            </p>
          </div>
        </div>
        <div className="family-japanese-name">
          <img src={moto} alt="moto" />
          <div className="family-japanese-name-meaning">
            <h4>Origem</h4>
            <p>
              Por outro lado, o ideograma "本" (hon, moto) é mais frequentemente
              entendido como livro, escritura ou cenário. O glifo “本” é
              derivado do caractere “木”(árvore) com a adição de um traço extra
              em seu tronco, para acentuar suas raízes , portanto também pode
              significar origem, causa, início, raiz ou tronco.
            </p>
          </div>
        </div>
        <div className="family-japanese-name">
          <img src={plus} alt="plus" />
          <div className="family-japanese-name-meaning">
            <h4>Dos montes e colinas</h4>
            <p>
              As palavras que compõem o nome Tsukamoto, "塚本", possuem diversos
              significados, além de representarem o sobrenome. Nesse caso, é
              provavelmente um sobrenome toponímico, derivado do local de
              residência dos antepassados, pois sua tradução literal seria "Da
              região da colina ou monte".
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
