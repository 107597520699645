import React from "react";
import logo from "../images/logo/logo-h-36-white.svg";
import face from "../images/icons/facebook.svg";
import instagram from "../images/icons/instagram.svg";
// import youtube from '../images/icons/youtube.svg';
// import twitter from '../images/icons/twitter.svg';
import "../styles/Footer.css";

import whatsappIcon from "../images/icons/whatsapp-icon.svg";
import { whatsappLink } from "../js/contact-info";

export default function Footer() {
  return (
    <footer id="lp-footer" className="footer-container">
      <div className="footer-background">
        <div className="footer-presentation">
          <div>
            <img
              style={{ marginBottom: "18px" }}
              src={logo}
              alt="logo"
            />
          </div>
          <div>
            <p>Copyright 2023-Tsukamoto</p>
          </div>
        </div>
        <div className="footer-social-media">
          <p>Siga nossas redes!</p>
          <div className="footer-img">
            <img src={face} alt="face" />
            <img src={instagram} alt="instagram" />
          </div>
        </div>
      </div>
      <a
        href={whatsappLink}
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-contact"
      >
        <img src={whatsappIcon} alt="Entrar em contato por Whatsapp" />
      </a>
    </footer>
  );
}
