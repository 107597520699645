import React from "react";

export default function SectionParagraph({
  paragraph,
  tips,
  secondTitle,
  secondParagraph,
  secondTips,
}) {
  return (
    <div className="sectionParagraph-container">
      {paragraph.map((element, index) => {
        return (
          <div key={index} className="sectionParagraph-subcontainer">
            <img src={element.icon} alt="Add" />
            <div>
              <h4 className="sectionParagraph-title">{element.title}</h4>
              <p className="sectionParagraph-content">{element.content}</p>
            </div>
          </div>
        );
      })}
      {tips ? (
        <div className="sectionParagraph-subcontainer">
          <img src={tips.icon} alt="Add Circulo" />
          <div>
            <h4 className="sectionParagraph-title">{tips.title}</h4>
            <ul>
              {tips
                ? tips.content.map((element, index) => (
                    <li key={index} className="sectionParagraph-li">
                      {element}
                    </li>
                  ))
                : ""}
            </ul>
          </div>
        </div>
      ) : (
        false
      )}
      {secondTitle ? (
        <div className="sectionSecondParagraph-subcontainer">
          <h4 className="sectionParagraph-second__title">{secondTitle}</h4>
        </div>
      ) : (
        false
      )}
      {secondParagraph
        ? secondParagraph.map((element, index) => (
            <div key={index} className="sectionParagraph-subcontainer">
              <img src={element.icon} alt="" />
              <div>
                <h4 className="sectionParagraph-title">{element.title}</h4>
                <p className="sectionParagraph-content">{element.content}</p>
              </div>
            </div>
          ))
        : false}
      {secondTips ? (
        <div className="sectionParagraph-subcontainer">
          <img src={secondTips.icon} alt="" />
          <div>
            <h4 className="sectionParagraph-title">{secondTips.title}</h4>
            <ul>
              {tips
                ? secondTips.content.map((element, index) => (
                    <li key={index} className="sectionParagraph-li">
                      {element}
                    </li>
                  ))
                : ""}
            </ul>
          </div>
        </div>
      ) : (
        false
      )}
    </div>
  );
}
