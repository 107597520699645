import React from "react";
import DentistCard from "./DentistCard";
import fabio from "../images/pictures/Fabio.webp";
import lucas from "../images/pictures/Lucas.webp";
import marcia from "../images/pictures/Marcia.webp";
import "../styles/Dentist.css";

import dotsDivider from "../images/icons/dot-divider.svg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

export default function Dentists() {
  const dentistCards = [
    {
      id: 0,
      img: lucas,
      name: "Dr. Lucas Tsukamoto",
      content:
        "O Dr. Lucas Tsukamoto é um cirurgião dentista formado pela PUC-PR. Ele atua como clínico geral, com enfoque em diversos tratamentos odontológicos. Sua paixão pela área odontológica começou desde jovem, inspirado pela família, e seu objetivo é ajudar as pessoas a conquistar um sorriso saudável.",
    },
    {
      id: 1,
      img: marcia,
      name: "Dr. Márcia Tsukamoto",
      content:
        "Sic de isto et tutius perducit ad actum ipsum, ut si dico “Ego autem vadam lavari, ut mens mea in statu naturae conformior.",
    },
    {
      id: 2,
      img: fabio,
      name: "Dr. Fábio Tsukamoto",
      content:
        "Meu nome é Fábio Seiji Tsukamoto, sou cirurgião Dentista desde 2020 formado pela Universidade Federal do Paraná (UFPR), Especialista em Prótese Dentária, atuando principalmente em restaurações diretas com resinas compostas, clareamento dental, pino de fibra intra-radiculares e restaurações indiretas com cerâmicas odontológicas. Desde criança estou nesse meio odontológico influenciado pela minha família, sonhar em poder ajudar as pessoas começando pelo sorriso e saúde sempre foi a minha meta.",
    },
  ];

  return (
    <div id="lp-dentists" className="dentist-container">
      <img
        className="dots-divider"
        src={dotsDivider}
        alt="Divisor de conteúdo"
      />
      <div>
        <h3 className="title-dentist">Nossos Dentistas</h3>
        <p className="subtitle-dentist">
          Conheça o motivo da fidelidade dos nossos clientes.
        </p>
      </div>
      <div className="container-cards-dentists">
        <Swiper
          slidesPerView={1}
          loop={true}
          centeredSlides={true}
          spaceBetween={0}
          autoplay={{ delay: 5000, pauseOnMouseEnter: true }}
          initialSlide={0}
          modules={[Autoplay, Navigation]}
          breakpoints={{
            1919: {
              slidesPerView: "auto",
              loop: false,
              pagination: false,
              allowTouchMove: false,
              initialSlide: 2,
              autoplay: false,
            },
            1151: {
              slidesPerView: "auto",
              loop: false,
              pagination: false,
              centeredSlides: false,
              freeMode: true,
              autoplay: false,
            },
            767: {
              slidesPerView: "auto",
              loop: false,
              pagination: false,
              centeredSlides: false,
              freeMode: true,
              autoplay: false,
            },
            539: {},
          }}
        >
          {dentistCards.map((card, index) => {
            return (
              <SwiperSlide key={index}>
                <DentistCard
                  key={card.id}
                  img={card.img}
                  name={card.name}
                  content={card.content}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}
