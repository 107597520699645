import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import Header from "../components/treatmentPage/Header";
import Information from "../components/Information";
import Description from "../components/treatmentPage/Description";
import SectionParagraph from "../components/treatmentPage/SectionParagraph";

import ReviewCard from "../components/ReviewCard";

import Location from "../components/Location";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

import imgCarie from "../images/pictures/treatment-carie.webp";
import imgGengivite from "../images/pictures/treatment-gengivite.webp";
import imgPeriodontite from "../images/pictures/treatment-periodontite.webp";
import imgSensibilidade from "../images/pictures/treatment-sensibilidade.webp";
import imgHalitose from "../images/pictures/treatment-halitose.webp";
import imgDentesInclusos from "../images/pictures/treatment-dentes-inclusos.webp";
// import imgClareamento from "../images/pictures/treatment-clareamento.webp";
import imgTraumas from "../images/pictures/treatment-trauma.webp";
import imgProteseDentaria from "../images/pictures/protese-dentaria.webp";
import imgFacetasLentes from "../images/pictures/facetas-e-lentes.webp";
import imgBruxismo from "../images/pictures/treatment-bruxismo.webp";
// import imgComplicacoesOrtodontia from "../images/pictures/treatment-complicações.webp";
// import imgTratamento from "../images/pictures/treatment-tratamentos.webp";
import add from "../images/icons/add.svg";
import addCircule from "../images/icons/add-circle-outline.svg";

import {
  marciaReviews,
  fabioReviews,
  // lucasReviews
} from "../js/reviews";

import { Helmet } from "react-helmet";

export default function TreatmentPage() {
  const { name } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const CONTENT = {
    caries: {
      img: imgCarie,
      titleImg: "Cáries: Causas, sintomas e tratamento",
      labelImg: "Dor e inflamação",
      contentImg:
        "A cárie é uma doença bucal causada pela ação de bactérias que produzem ácidos que desmineralizam o esmalte dentário. Se não tratada, pode evoluir e atingir a polpa do dente, causando dor e inflamação. O tratamento inclui a remoção do tecido cariado e a restauração do dente.",
      paragraph: [
        {
          icon: add,
          title: "Tipos de Cáries",
          content:
            "A cárie dentária é um problema comum que afeta a saúde bucal de muitas pessoas ao redor do mundo. Resultante da destruição dos elementos dentários, a cárie se desenvolve devido à presença de bactérias, dieta rica em carboidratos e higiene oral precária. Essas condições permitem que as bactérias, como o Streptococcus mutans, se proliferem e produzam ácidos que corroem a superfície dos dentes. Existem diferentes tipos de cárie, como a coronária, que afeta tanto crianças quanto adultos, a radicular, mais comum em pessoas idosas, e a recorrente, que ocorre ao redor de restaurações e coroas já existentes.",
        },
        {
          icon: add,
          title: "Perigos",
          content:
            "Os problemas causados pela cárie dentária não devem ser negligenciados. Se não tratada adequadamente, a cárie pode atingir a dentina, causando dor e sensibilidade na área afetada. Além disso, se a cárie não for removida, pode evoluir para um tratamento endodôntico, como o tratamento de canal. A falta de cuidado com a cárie também pode levar a infecções focais, que podem se espalhar para outras partes do corpo.",
        },
        {
          icon: add,
          title: "Prevenção",
          content:
            "É importante destacar que a cárie dentária é uma doença infectocontagiosa, causada pela fermentação microbiana dos carboidratos da alimentação. Por isso, além de cuidar da saúde bucal individualmente, é fundamental conscientizar a sociedade sobre a importância da prevenção e do tratamento adequado da cárie. Com hábitos saudáveis e acompanhamento profissional, é possível prevenir a cárie dentária e manter um sorriso saudável.",
        },
      ],
      tips: {
        icon: addCircule,
        title: "Dicas para evitar a cárie e manter uma boa saúde bucal:",
        content: [
          "Escove os dentes pelo menos três vezes ao dia e use o fio dental diariamente.",
          "Faça visitas regulares ao dentista para avaliações e prevenção de problemas.",
          "Tenha uma alimentação equilibrada, evitando excesso de amido e açúcar.",
          "Utilize produtos de higiene bucal com flúor, incluindo creme dental e enxaguante.",
          "Verifique se a água da sua casa contém flúor e, se necessário, use suplementos recomendados pelo dentista.",
        ],
      },
      dentist: marciaReviews,
    },
    gengivite: {
      img: imgGengivite,
      titleImg: "Gengivite: Causas, sintomas e tratamento",
      labelImg: "Inflamação das gengivas",
      contentImg:
        "A gengivite é uma condição inflamatória que afeta os tecidos moles que revestem e suportam os dentes. Ela é caracterizada pela presença de inchaço nas gengicas e sangramento constante na hora de escovar os dentes e passar o fio dental. Se não for tratada pode evoluir para uma ",
      linkUrl: "/treatment/periodontite",
      linkDesc: "Periodontite.",
      paragraph: [
        {
          icon: add,
          title: "Quais são as causas da gengivite?",
          content:
            "A gengivite é geralmente causada pela acumulação de placa bacteriana nos dentes e ao redor da linha da gengiva. A placa bacteriana é uma película pegajosa e incolor que se forma constantemente nos dentes quando restos de alimentos e bactérias não são removidos adequadamente pela escovação e uso do fio dental.",
        },
        {
          icon: addCircule,
          title: "Como tratar a gengivite?",
          content:
            "O tratamento da gengivite envolve a remoção da placa bacteriana e a adoção de uma boa higiene bucal, incluindo escovação regular, uso de fio dental e visitas ao dentista para limpezas profissionais. A importância do tratamento da gengivite é para não evoluir para uma periodontite.",
        },
      ],
      dentist: marciaReviews,
    },
    periodontite: {
      img: imgPeriodontite,
      titleImg: "Periodontite: Causas, sintomas e tratamento",
      labelImg: "Gengivas retraídas",
      contentImg:
        "A periodontite é uma das principais causas de perda de dentes em adultos. A destruição do osso alveolar e a deterioração dos tecidos periodontais podem levar à mobilidade e ao eventual deslocamento dos dentes.",
      paragraph: [
        {
          icon: add,
          title: "O que é?",
          content:
            "A periodontite é uma doença inflamatória crônica que afeta as estruturas de suporte dos dentes, incluindo as gengivas, o osso alveolar e o ligamento periodontal. Ela é causada principalmente pela presença de placa bacteriana nos dentes e ao redor da linha da gengiva.",
        },
        {
          icon: add,
          title: "Quando Ocorre?",
          content:
            "A periodontite se desenvolve quando as bactérias presentes na placa e no tártaro irritam e inflamam as gengivas. Com o tempo, a inflamação se estende para os tecidos mais profundos que sustentam os dentes, levando à destruição do osso alveolar e à formação de bolsas periodontais, que são espaços vazios entre os dentes e as gengivas.",
        },
        {
          icon: add,
          title: "Quais são os sintomas?",
          content:
            "Os sintomas da periodontite incluem gengivas vermelhas, inchadas e sensíveis, sangramento durante a escovação ou uso do fio dental, mau hálito persistente, retração gengival, sensibilidade dentária, sensação de dentes soltos e mudanças na posição ou na mordida dos dentes.",
        },
      ],
      secondTitle: "Quais são as consequências?",
      secondParagraph: [
        {
          icon: addCircule,
          title: "Perda de dentes",
          content:
            "A periodontite é uma das principais causas de perda de dentes em adultos. A destruição do osso alveolar e a deterioração dos tecidos periodontais podem levar à mobilidade e ao eventual deslocamento dos dentes.",
        },
        {
          icon: add,
          title: "Deterioração da estética dental",
          content:
            "A periodontite pode causar retração gengival, exposição das raízes dentárias e perda de suporte ósseo. Isso pode levar a alterações estéticas, como dentes alongados, espaçamentos indesejados entre os dentes e alterações na posição dos dentes.",
        },
        {
          icon: add,
          title: "Problemas de mastigação e digestão",
          content:
            "A perda de dentes e a deterioração dos tecidos periodontais podem dificultar a mastigação adequada dos alimentos. Isso pode levar a problemas de digestão, nutrição inadequada e impacto na qualidade de vida.",
        },
        {
          icon: add,
          title: "Abscessos periodontais",
          content:
            "Em casos avançados de periodontite, as bolsas periodontais podem se tornar locais de acúmulo de bactérias e pus, resultando em abscessos periodontais. Essas infecções podem ser dolorosas e requerem tratamento imediato.",
        },
        {
          icon: add,
          title: "Dor e desconforto",
          content:
            "A inflamação das gengivas e a presença de abscessos periodontais podem causar dor e desconforto na região afetada. A sensibilidade dentária também pode estar presente, tornando a alimentação e a escovação dolorosas.",
        },
        {
          icon: add,
          title: "Problemas de fala",
          content:
            "A periodontite avançada, com perda de dentes ou alterações na posição dos dentes, pode afetar a fala e a pronúncia correta de certos sons.",
        },
        {
          icon: add,
          title: "Aumento do risco de doenças sistêmicas",
          content:
            "A periodontite avançada, com perda de dentes ou alterações na posição dos dentes, pode afetar a fala e a pronúncia correta de certos sons.",
        },
        {
          icon: addCircule,
          title: "Como tratar a periodontite?",
          content:
            "O tratamento da periodontite envolve uma abordagem multi etapas para controlar a infecção, promover a cicatrização dos tecidos periodontais e prevenir a progressão da doença. O primeiro passo no tratamento é realizar uma limpeza profissional dos dentes, chamada de raspagem e alisamento radicular. Esse procedimento é realizado pelo dentista ou pelo periodontista e consiste na remoção da placa bacteriana e do tártaro acima e abaixo da linha da gengiva, incluindo as bolsas periodontais. Em situações mais avançadas, quando a periodontite causou danos significativos nos tecidos periodontais e no osso alveolar, pode ser necessária a realização de cirurgias periodontais. Após o tratamento inicial, é essencial adotar uma rotina de manutenção periodontal regular. Isso inclui visitas periódicas ao dentista para avaliação da saúde periodontal, limpeza profissional regular e acompanhamento do progresso do tratamento. Além disso, o dentista fornecerá orientações sobre a higiene bucal adequada e cuidados contínuos para evitar a recorrência da periodontite. Muitas vezes o profissional precisa da ajuda do paciente para que os hábitos de higiene bucal sejam praticados.",
        },
        {
          icon: add,
          title: "Como prevenir a periodontite?",
          content:
            "A prevenção deve ser feita pela adoção de hábitos de higiene bucal como escovar os dentes pelo menos 2 vezes ao dia, uso de fio dental diariamente, escovação da língua e visitas regulares ao dentista para exames de rotina e profilaxias. Importante ressaltar que o fumo é um fator de risco para a periodontite, então, considere suspender o uso de tabaco.",
        },
      ],
      dentist: marciaReviews,
    },
    sensibilidade: {
      img: imgSensibilidade,
      titleImg: "Sensibilidade: Causas, sintomas e tratamento",
      labelImg: "Dor e desconforto",
      contentImg:
        "A sensibilidade é uma condição em que se sente desconforto ou dor nos dentes quando expostos a estímulos específicos, como alimentos quentes, frios, doces ou ácidos, escovação dos dentes, uso do fio dental ou até mesmo ar frio.",
      paragraph: [
        {
          icon: addCircule,
          title: "Quais são as causas da sensibilidade?",
          content:
            "A causa mais comum para sensibilidade é a retração gengival. Ocorre quando a gengiva se retrai e deixa a raiz do dente exposta. A raiz dentária não possui esmalte e é coberta apenas pela dentina, que é mais sensível a estímulos externos. E a retração gengival pode ser causada pela força excessiva na escovação, escovas com cerdas muito rígidas, bruxismo, oclusão (mordida) inadequada dos dentes.",
        },
        {
          icon: add,
          title: "",
          content:
            "A sensibilidade também pode ocorrer quando o paciente tem o hábito de bruxismo, escovação agressiva ou consumo de bebidas contendo ácidos que promovem o desgaste do esmalte expondo a dentina, onde estão presentes as terminações nervosas.",
        },
      ],
      dentist: marciaReviews,
    },
    halitose: {
      img: imgHalitose,
      titleImg: "Halitose: Causas, sintomas e tratamento",
      labelImg: "Mau hálito",
      contentImg:
        "O mau hálito, conhecido como halitose, pode ser causado por diversos fatores, como higiene bucal deficiente, problemas dentários, acúmulo de bactérias na língua e infecções . Agende uma consulta conosco para obter um diagnóstico preciso e receber orientações de tratamento adequadas caso esteja preocupado com o mau hálito persistente.",
      paragraph: [
        {
          icon: addCircule,
          title: "",
          content:
            "O mau hálito, também conhecido como halitose, pode ser causado por várias razões. Alguns dos principais fatores que contribuem para o mau hálito incluem:",
        },
        {
          icon: add,
          title: "Higiene bucal deficiente",
          content:
            "A falta de escovação e uso inadequado do fio dental pode levar ao acúmulo de partículas de alimentos e placa bacteriana na boca, o que pode causar o mau odor.",
        },
        {
          icon: add,
          title: "Problemas dentários",
          content:
            "Cáries, infecções na gengiva (gengivite ou periodontite), abscessos dentários e dentes em decomposição são exemplos de problemas dentários que podem causar mau hálito.",
        },
        {
          icon: add,
          title: "Língua",
          content:
            "A língua pode ser um local onde se acumulam bactérias e restos de comida, principalmente na parte de trás da língua, o que pode causar mau odor.",
        },
        {
          icon: add,
          title: "Tabagismo",
          content:
            "Fumar tabaco pode causar um odor desagradável persistente na boca, além de contribuir para problemas dentários e periodontais.",
        },
        {
          icon: add,
          title: "Boca seca",
          content:
            "A diminuição do fluxo de saliva na boca pode causar mau hálito. A saliva ajuda a limpar a boca, neutralizar ácidos e controlar o crescimento bacteriano.",
        },
        {
          icon: add,
          title: "Infecções do trato respiratório superior",
          content:
            "Infecções na garganta, amígdalas ou seios paranasais podem causar mau hálito devido à presença de bactérias ou muco infectado.",
        },
        {
          icon: addCircule,
          title: "",
          content:
            "Se você estiver preocupado com o mau hálito persistente, é recomendável que consulte um dentista ou médico para obter um diagnóstico preciso e orientações adequadas de tratamento.",
        },
      ],
      dentist: marciaReviews,
    },
    "dentes-inclusos": {
      img: imgDentesInclusos,
      titleImg: "Dentes Inclusos (sisos)",
      labelImg: "Infecções e danos",
      contentImg: `Os dentes do siso costumam ser chamados de "siso", porque, erupcionam na fase em que uma pessoa está se tornando mais sábia ou "ajuizada". No entanto, nem todos desenvolvem dentes do siso, e alguns podem ter apenas um ou dois, ao invés de quatro.`,
      paragraph: [
        {
          icon: add,
          title: "O que são dentes do siso?",
          content: `O dente do siso, também conhecido como terceiro molar, é o último dente a se desenvolver na boca humana e geralmente erupciona entre os 17 e 25 anos de idade, embora esse período possa variar de pessoa para pessoa. A maioria das pessoas possui quatro dentes do siso, um em cada canto da arcada dentária, dois superiores e dois inferiores. Os dentes do siso costumam ser chamados de "siso", porque, erupcionam na fase em que uma pessoa está se tornando mais sábia ou "ajuizada". No entanto, nem todos desenvolvem dentes do siso, e alguns podem ter apenas um ou dois, ao invés de quatro. Em alguns casos, os dentes do siso podem não ter espaço suficiente para erupcionar corretamente, ficando presos ou parcialmente erupcionados. Essa condição é conhecida como "dente do siso incluso".`,
        },
        {
          icon: add,
          title: "Porque ficam inclusos?",
          content:
            "Os dentes do siso podem ficar inclusos por uma série de razões. A principal delas é a falta de espaço na arcada dentária para acomodar esses dentes extras. Quando não há espaço suficiente, os dentes do siso podem ficar presos na gengiva ou no osso, impedindo sua erupção normal. Eles podem ficar completamente inclusos, o que significa que estão totalmente cobertos pela gengiva e pelo osso, ou parcialmente inclusos, quando parte do dente consegue romper a gengiva, mas não completamente.",
        },
      ],
      tips: {
        icon: addCircule,
        title:
          "Outros fatores que podem contribuir para a inclusão dos dentes do siso incluem:",
        content: [
          "Ângulo de erupção incorreto: Os dentes do siso podem se inclinar em ângulos desfavoráveis, dificultando sua erupção adequada.",
          "Obstrução na trajetória de erupção: Se existirem outros dentes ou estruturas ósseas bloqueando o caminho de erupção dos dentes do siso, eles podem ficar inclusos.",
          "Tecido mole excessivo: Às vezes, a quantidade de tecido mole (gengiva) ao redor dos dentes do siso é excessiva, o que dificulta a erupção normal.",
        ],
      },
      secondParagraph: [
        {
          icon: add,
          title: "Quais complicações podem levar?",
          content:
            "Os dentes do siso podem ficar inclusos por uma série de razões. A principal delas é a falta de espaço na arcada dentária para acomodar esses dentes extras. Quando não há espaço suficiente, os dentes do siso podem ficar presos na gengiva ou no osso, impedindo sua erupção normal. Eles podem ficar completamente inclusos, o que significa que estão totalmente cobertos pela gengiva e pelo osso, ou parcialmente inclusos, quando parte do dente consegue romper a gengiva, mas não completamente.",
        },
        {
          icon: add,
          title: "Quais complicações podem levar?",
          content:
            "A inclusão dos dentes do siso pode levar a complicações, como dor, inflamação, infecção, cárie dentária, formação de cistos ou tumores, danos aos dentes adjacentes, desalinhamento dos dentes e problemas de oclusão (mordida). Portanto, em muitos casos, é recomendada a extração dos dentes do siso inclusos para prevenir problemas futuros",
        },
      ],
      secondTips: {
        icon: addCircule,
        title: "Os dentes do siso devem sempre ser removidos?",
        content: [
          "Nem sempre os sisos devem ser removidos, mas na maioria dos casos sim. Alguns dos motivos para a extração são:",
          "Dificuldade de higienização: Devido à sua localização no fundo da boca, os dentes do siso podem ser difíceis de alcançar e higienizar corretamente. Isso pode levar ao acúmulo de placa bacteriana, cárie dentária e doença periodontal.",
          "Impactação: Quando os dentes do siso ficam presos parcialmente erupcionados ou completamente inclusos, eles podem empurrar ou danificar os dentes vizinhos, causando dor, inflamação ou danos estruturais.",
          "Infecção: A presença de dentes do siso inclusos pode aumentar o risco de infecções, como a pericoronarite, que ocorre quando a gengiva ao redor do dente do siso está inflamada e infectada.",
          "Formação de cistos ou tumores: Em alguns casos, um cisto ou tumor pode se formar ao redor do dente do siso incluso, o que pode causar danos ao osso circundante e a outros dentes.",
        ],
      },
      dentist: marciaReviews,
    },
    // clareamento: {
    //   img: imgClareamento,
    //   titleImg: "Clareamento",
    //   labelImg: "",
    //   contentImg: ``,
    //   paragraph: [
    //     {
    //       title: "",
    //       content: ``,
    //     },
    //   ],
    // dentist: marciaReviews
    // },
    "trauma-dentario": {
      img: imgTraumas,
      titleImg: "Trauma Dental",
      labelImg: "Fratura, avulsão e luxação",
      contentImg:
        "O traumatismo dentário é uma ocorrência muito frequente em crianças e adolescentes e gera um grande susto aos pais. As lesões traumáticas dentárias podem variar desde simples fraturas em esmalte até a perda definitiva do elemento dentário, como consequência de quedas, brigas ou lutas, acidentes esportivos, automobilísticos, traumatismos com objetos.",
      paragraph: [
        {
          icon: add,
          title: "Como proceder?",
          content:
            "Por falta de conhecimento dos responsáveis, geralmente o primeiro atendimento ocorre em prontos-socorros, clínicas médicas e postos de saúde, o que pode acarretar um mau prognóstico do trauma. O ideal é que o primeiro atendimento seja realizado de forma imediata por um cirurgião dentista e, nesse atendimento, seja realizado um exame clínico e radiográfico do paciente traumatizado para determinar o diagnóstico inicial, a gravidade da lesão e o plano de tratamento. É de suma importância salientar que o sucesso do tratamento está diretamente relacionado com a rapidez e a eficiência dos primeiros socorros do paciente.",
        },
        {
          icon: add,
          title: "Indicações",
          content:
            "Caso não tenha um cirurgião dentista disponível em tal situação, o indicado seria armazenar o fragmento dental em soro fisiológico, água ou leite para posteriormente ser colado este fragmento em consultório.",
        },
        {
          icon: addCircule,
          title: "Tratamento",
          content:
            "O tipo de tratamento dependerá da extensão da fratura dentária, desde a colagem do fragmento dental, restauração do dente em resina composta, tratamento de canal até a extração do dente e planejamento de uma prótese dentária.",
        },
      ],
      dentist: marciaReviews,
    },
    bruxismo: {
      img: imgBruxismo,
      titleImg: "Bruxismo: Causas, sintomas e tratamento",
      labelImg: "Desgaste dentário",
      contentImg:
        "O bruxismo é uma condição caracterizada pelo hábito de ranger ou apertar os dentes de forma involuntária. É considerado um distúrbio do movimento relacionado à atividade muscular da mastigação.",
      paragraph: [
        {
          icon: add,
          title: "Quais são os sintomas do bruxismo?",
          content:
            "Os sintomas do bruxismo podem incluir o ranger audível dos dentes, desgaste dental excessivo, sensibilidade nos dentes, dor de cabeça, dor facial, dor na mandíbula, dor no pescoço e nos ombros, problemas na articulação temporomandibular (ATM), bem como problemas no sono e cansaço ao acordar. Em casos graves, o bruxismo pode levar a danos nos dentes, como rachaduras, fraturas e perda de esmalte dental.",
        },
        {
          icon: add,
          title: "O que causa o bruxismo?",
          content:
            "As causas do bruxismo ainda não são totalmente compreendidas, mas fatores como estresse emocional, ansiedade, tensão, má oclusão dentária (encaixe inadequado dos dentes) e distúrbios do sono, como a apneia do sono, podem contribuir para o desenvolvimento do bruxismo.",
        },
        {
          icon: addCircule,
          title: "Como tratar o bruxismo?",
          content:
            "Em casos leves, pode ser recomendado o uso de placas interoclusais (plaquinhas), que são dispositivos usados durante o sono para proteger os dentes e reduzir o ranger. Medidas para reduzir o estresse e a ansiedade, como terapia de relaxamento, exercícios físicos e a aplicação de técnicas de gerenciamento do estresse, também podem ser úteis. Em casos mais graves, o dentista ou médico especialista pode indicar outros tratamentos, como fisioterapia, medicamentos e terapia psicológica.",
        },
      ],
      dentist: marciaReviews,
    },
    "protese-dentaria": {
      img: imgProteseDentaria,
      titleImg: "Prótese Dentária",
      labelImg: "Perda dos dentes",
      contentImg:
        "A prótese dentária é um dispositivo utilizado para substituir o(s) dente(s) perdido(s). Elas podem ser removíveis (encaixadas à boca), ou fixas (implantadas). A escolha depende do tipo de tratamento mais indicado para o caso.",
      paragraph: [
        {
          icon: add,
          title: "O que é?",
          content:
            "A perda dos dentes sempre é um motivo de desconforto e preocupação. Além de prejudicar a estética de seu sorriso, esse dano também pode dificultar a fala, a mastigação e a formação de toda a arcada dentária. A prótese dentária é um dispositivo utilizado para substituir o(s) dente(s) perdido(s). Elas podem ser removíveis (encaixadas à boca), ou fixas (implantadas). A escolha depende do tipo de tratamento mais indicado para o caso. Elas podem ser confeccionadas com base em diversos tipos de materiais, como resina, metais, porcelana e zircônia. Apesar de demandarem algum tempo para aplicação e adaptação, a aparência das próteses de hoje é muito semelhante à estética dos dentes naturais. Por isso, o seu uso passa a ser imperceptível para a maioria das pessoas, proporcionando mais conforto e qualidade de vida aos usuários.",
        },
        {
          icon: add,
          title: "Como funciona uma prótese dentária?",
          content:
            "Todas as próteses são feitas sob medida, a partir dos moldes retirados da boca do paciente. O dentista trabalhará em parceria com um técnico especialista para o desenvolvimento de um dispositivo compatível com suas necessidades e será o responsável direto pela sua aplicação ou implantação.",
        },
        {
          icon: add,
          title: "Qual é o melhor tipo de prótese?",
          content:
            "São vários os tipos de prótese e cada uma é mais indicada para casos específicos. A protocolo, por exemplo, é uma prótese dentária ideal para quem perdeu todos os dentes, enquanto a fixa é uma indicação para quem perdeu um ou mais dentes. Assim, o melhor tipo vai depender de perfil, hábitos e características de cada paciente. Dessa forma, a escolha deve ser feita pelo paciente com a orientação do seu dentista. O profissional poderá avaliar as necessidades do seu paciente e indicar o tipo de prótese mais adequado e que atenda às suas expectativas.",
        },
        {
          icon: add,
          title: "Quando é indicado o uso de prótese dentária?",
          content:
            "A prótese dentária é indicada para aqueles que perderam todos, um ou mais dentes naturais.",
        },
      ],
      dentist: fabioReviews,
    },
    "facetas-e-lentes": {
      img: imgFacetasLentes,
      titleImg: "Facetas e Lentes de Contato Dental",
      labelImg: "Irregularidades e manchas",
      contentImg:
        "Geralmente são feitas em dentes anteriores e permitem mudar a cor, formato e tamanho dos dentes, de forma natural e harmoniosa. São indicadas quando há maior comprometimento, como em dentes que já possuem restaurações antigas, dentes muito manchados ou ligeiramente fora de posição.",
      paragraph: [
        {
          icon: add,
          title: "O que é?",
          content:
            "Também consideradas Próteses Dentárias, geralmente são feitas em dentes anteriores e, permitem mudar a cor, formato e tamanho dos dentes, de forma natural e harmoniosa. Normalmente as facetas são indicadas em dentes com maior comprometimento, por exemplo dentes que já possuem restaurações antigas, dentes muito manchados ou ligeiramente fora de posição. A Lente de Contato Dental é extremamente mais fina e exige pouco ou nenhum preparo no dente (desgaste dental), ou seja, a lente de contato é um procedimento que desgasta menos o dente.  O que irá definir a indicação para que o dente receba a lente de contato dental ou a faceta é o planejamento de cada caso.",
        },
        {
          icon: add,
          title: "Qual a diferença em relação a uma restauração de resina?",
          content:
            "A Lente de Contato Dental é uma fina camada de porcelana que recobre a parte da frente do Dente, e pelo fato de ser confeccionada fora da boca, apresenta um resultado estético mais favorável e previsível. Preciso lembrar também que a restauração de resina pode sofrer alteração de cor com o tempo ou manchamento com café e cigarro, o que é muito mais difícil de ocorrer nas peças de porcelana.",
        },
        {
          icon: add,
          title: "Quantos dentes preciso fazer?",
          content:
            "É preciso avaliar cada caso de forma individual para ver como resolver a queixa do paciente e as expectativas em relação ao tratamento. Normalmente é feito em todos os dentes que aparecem quando a pessoa sorri, apresentando então um sorriso mais harmonioso.",
        },
      ],
      dentist: fabioReviews,
    },
    // "dentes-ortodontia": {
    //   img: imgComplicacoesOrtodontia,
    //   titleImg: "",
    //   labelImg: "",
    //   contentImg: "",
    //   paragraph: [
    //     {
    //       title: "",
    //       content: "",
    //     },
    //   ],
    // dentist: marciaReviews,
    // },
    // tratamentos: {
    //   img: imgTratamento,
    //   titleImg: "Outros Tratamentos",
    //   labelImg: "",
    //   contentImg:
    //     "",
    //   paragraph: [
    //     {
    //       title: "",
    //       content:
    //         "",
    //     },
    //   ],
    // dentist: marciaReviews,
    // },
  };

  let metaContent = "Tsukamoto Tratamentos Odontológicos, " + name;
  return (
    <div>
      <Helmet>
        <title>Tratamentos Odontológicos</title>
        <meta name="description" content={metaContent} />
      </Helmet>
      <Header />
      <Information />
      <Description
        img={CONTENT[name].img}
        title={CONTENT[name].titleImg}
        label={CONTENT[name].labelImg}
        content={CONTENT[name].contentImg}
        linkUrl={CONTENT[name].linkUrl}
        linkDesc={CONTENT[name].linkDesc}
      />
      <SectionParagraph
        paragraph={CONTENT[name].paragraph}
        tips={CONTENT[name].tips}
        secondTitle={CONTENT[name].secondTitle}
        secondParagraph={CONTENT[name].secondParagraph}
        secondTips={CONTENT[name].secondTips}
      />
      <ReviewCard
        img={CONTENT[name].dentist.img}
        name={CONTENT[name].dentist.name}
        where={CONTENT[name].dentist.where}
        reviews={CONTENT[name].dentist.reviews}
        reviewsNames={CONTENT[name].dentist.reviews.names}
      />
      <Location />
      <Contact />
      <Footer />
    </div>
  );
}
