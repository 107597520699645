import React from 'react';
import '../styles/Contact.css';

export default function Contact() {
  return (
    <div id="lp-contact" className='contact-container'>
      <div>
        <h3 className='contact-title'>
          Contato
        </h3>
        <p className='contact-subtitle'>
          Sua família em boas mãos.
        </p>
      </div>
      <div className='contact-inputs-container'>
        <div>
          <input
            style={{ width: "326px"}}
            placeholder="Nome e Sobrenome"
            type="text"
          />
          <input
            style={{ width: "251px"}}
            placeholder="Telefone"
            type="tel"
          />
          <input
            style={{ width: "250px"}}
            placeholder='e-mail'
            type="email"
          />
        </div>
        <div>
          <input
            style={{ width: "857px"}}
            placeholder='Mensagem'
            type="text"
          />
        </div>
      </div>
      <div className='contact-button-container'>
        <button className='contact-button'>
          <p>
            Enviar Mensagem
          </p>
        </button>
      </div>
    </div>
  )
}
