import logoH36White from "../images/logo/logo-h-36-white.svg";
import logoH36 from "../images/logo/logo-h-36.svg";

import { Link } from "react-router-dom";

import burger from "../images/icons/burger.svg";
import { useState } from "react";

export default function Header() {
  const [mobileHeader, setMobileHeader] = useState({
    transform: "translateX(-100vw)",
  });

  const handleClick = (event) => {
    const { target } = event;

    const hash = target.getAttribute("hash");
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        setMobileHeader({
          transform: "translateX(-100vw)",
        });
      }
    }
  };

  const handleMobileHeader = (action) => {
    if (action === "open") {
      setMobileHeader({
        transform: "translateX(0vw)",
      });
    } else {
      setMobileHeader({
        transform: "translateX(-100vw)",
      });
    }
  };

  const windowWidth = window.innerWidth;

  return (
    <header className="header-container">
      <div className="header-container header__mobile" style={mobileHeader}>
        <div className="header__mobile__top">
          <button
            onClick={() => {
              handleMobileHeader("close");
            }}
            className="header__burger"
          >
            <img src={burger} alt="header burger" />
          </button>
          <Link to={"/"}>
            <img
              src={windowWidth >= 960 ? logoH36White : logoH36}
              alt="Logo Tsukamoto"
            />
          </Link>
        </div>
        <div className="button-container">
          <Link
            to={{ pathname: "/", hash: "#lp-homecare" }}
            className="button-links"
            onClick={handleClick}
          >
            <span className="name-links" hash="lp-homecare">
              Atendimento Domiciliar
            </span>
          </Link>
          <Link
            to={{ pathname: "/", hash: "#lp-specialties" }}
            className="button-links"
            onClick={handleClick}
          >
            <span className="name-links" hash="lp-specialties">
              Especialidades
            </span>
          </Link>
          <Link
            to={{ pathname: "/", hash: "#lp-dentists" }}
            className="button-links"
            onClick={handleClick}
          >
            <span className="name-links" hash="lp-dentists">
              Dentistas
            </span>
          </Link>
          <Link
            to={{ pathname: "/", hash: "#lp-treatments" }}
            className="button-links"
            onClick={handleClick}
          >
            <span className="name-links" hash="lp-treatments">
              Tratamentos
            </span>
          </Link>
          <Link
            to={{ pathname: "/", hash: "#lp-location" }}
            className="button-links"
            onClick={handleClick}
          >
            <span className="name-links" hash="lp-location">
              Localização
            </span>
          </Link>
        </div>
      </div>
      <button
        onClick={() => {
          handleMobileHeader("open");
        }}
        className="header__burger"
      >
        <img src={burger} alt="header burger" />
      </button>
      <Link to={"/"}>
        <img
          src={windowWidth >= 960 ? logoH36White : logoH36}
          alt="Logo Tsukamoto"
        />
      </Link>
      <div className="button-container">
        <Link
          to={{ pathname: "/", hash: "#lp-homecare" }}
          className="button-links"
          onClick={handleClick}
        >
          <span className="name-links" hash="lp-homecare">
            Atendimento Domiciliar
          </span>
        </Link>
        <Link
          to={{ pathname: "/", hash: "#lp-specialties" }}
          className="button-links"
          onClick={handleClick}
        >
          <span className="name-links" hash="lp-specialties">
            Especialidades
          </span>
        </Link>
        <Link
          to={{ pathname: "/", hash: "#lp-dentists" }}
          className="button-links"
          onClick={handleClick}
        >
          <span className="name-links" hash="lp-dentists">
            Dentistas
          </span>
        </Link>
        <Link
          to={{ pathname: "/", hash: "#lp-treatments" }}
          className="button-links"
          onClick={handleClick}
        >
          <span className="name-links" hash="lp-treatments">
            Tratamentos
          </span>
        </Link>
        <Link
          to={{ pathname: "/", hash: "#lp-location" }}
          className="button-links"
          onClick={handleClick}
        >
          <span className="name-links" hash="lp-location">
            Localização
          </span>
        </Link>
      </div>
    </header>
  );
}
