import React, { useState } from "react";
import showIcon from "../images/icons/show-icon.svg";
import hiddenIcon from "../images/icons/hidden-icon.svg";
import "../styles/Dentist.css";
import { whatsappLink } from "../js/contact-info";

const DentistCard = ({ img, name, content }) => {
  const [showContent, setShowContent] = useState(false);

  // const handleMouseEnter = () => {
  //   setShowContent(true);
  // };

  // const handleMouseLeave = () => {
  //   setShowContent(false);
  // };

  const handleClick = () => {
    if (showContent === true) {
      setShowContent(false);
    } else {
      setShowContent(true);
    }
  };

  return (
    <div className="cards-dentist">
      <img className="cards-dentist-img" src={img} alt={img} loading="lazy" />
      <div
        className={`cards-dentists-content ${showContent ? "show" : ""}`}
        style={{ height: showContent ? 257 : 96 }}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {showContent ? (
          <div
            className={`cards-dentists-subcontent ${showContent ? "show" : ""}`}
          >
            <div className="cards-dentists-content-hidden">
              <div className="cards-dentists-title">
                <span>{name}</span>
                <img
                  src={showContent ? hiddenIcon : showIcon}
                  alt={showContent ? "hiddenIcon" : "showIcon"}
                />
              </div>
              <p className="cards-dentists-subtitle">Cirurgião-Dentista</p>
              <p className="cards-dentists-content-text-hidden">{content}</p>
              <div className="cards-dentists-button-container-hidden">
                <a
                  href={whatsappLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cards-dentists-button-hidden"
                >
                  <p className="cards-dentists-button-name-hidden">Agendar</p>
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="cards-names-fixed">
            <div className="cards-dentists-title">
              <span>{name}</span>
              <img
                src={showContent ? hiddenIcon : showIcon}
                alt={showContent ? "hiddenIcon" : "showIcon"}
              />
            </div>
            <p className="cards-dentists-subtitle">Cirurgião-Dentista</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DentistCard;
