import React from "react";
import location from "../images/icons/location-on.svg";
import phone from "../images/icons/phone-in-talk.svg";
import people from "../images/icons/poeple heart.svg";
import "../styles/Information.css";

import { whatsappText } from "../js/contact-info";

export default function Information() {
  return (
    <div id="lp-information" className="information-container">
      <div className="information-card">
        <div className="name-img-card">
          <img src={location} alt="location" />
          <span className="name-card">Localização</span>
        </div>
        <div className="content-container-card">
          <p className="content-card">
            Rua Maestro Carlos Frank 1960, sala 16 Boqueirão, Curitiba
          </p>
        </div>
      </div>
      <div className="information-card">
        <div className="name-img-card">
          <img src={phone} alt="phone" />
          <span className="name-card">Contato</span>
        </div>
        <div className="content-container-card">
          {/* <p className='content-card'>
            (41) 3082-0661
          </p> */}
          <p className="content-card">{whatsappText}</p>
        </div>
      </div>
      <div className="information-card">
        <div className="name-img-card">
          <img src={people} alt="people" />
          <span className="name-card">Atendimento</span>
        </div>
        <div className="content-container-card">
          <p className="content-card">Idosos</p>
          <p className="content-card">Adultos</p>
          <p className="content-card">Crianças (+6 anos)</p>
        </div>
      </div>
    </div>
  );
}
