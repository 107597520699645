import React from "react";
import logo from "../../images/logo/logo-h-36.svg";
import "../../styles/treatmentPage/Header.css";
import { Link } from "react-router-dom";

export default function Header() {
  const handleClick = (event) => {
    const { target } = event;
    console.log(target);
  };

  return (
    <header className="treatmentPage-header-container">
      <Link to={"/"}>
        <img src={logo} alt="logo" />
      </Link>
      <div className="treatmentPage-header-link-container">
        <Link
          to={{ pathname: "/", hash: "#lp-homecare" }}
          onClick={handleClick}
        >
          <span className="treatmentPage-header-link">
            Atendimento Domiciliar
          </span>
        </Link>
        <Link
          to={{ pathname: "/", hash: "#lp-specialties" }}
          onClick={handleClick}
        >
          <span className="treatmentPage-header-link">Especialidades</span>
        </Link>
        <Link
          to={{ pathname: "/", hash: "#lp-dentists" }}
          onClick={handleClick}
        >
          <span className="treatmentPage-header-link">Dentistas</span>
        </Link>
        <Link
          to={{ pathname: "/", hash: "#lp-treatments" }}
          onClick={handleClick}
        >
          <span className="treatmentPage-header-link">Tratamentos</span>
        </Link>
        <Link
          to={{ pathname: "/", hash: "#lp-location" }}
          onClick={handleClick}
        >
          <span className="treatmentPage-header-link">Localização</span>
        </Link>
      </div>
    </header>
  );
}
