import React from "react";
import "../styles/Treatment.css";

import TreatmentCard from "./TreatmentCard";

import caries from "../images/treatmentCards/caries.jpg";
import gengivite from "../images/treatmentCards/gengivite.jpg";
import periodontite from "../images/treatmentCards/periodontite.jpg";
import sensibilidade from "../images/treatmentCards/sensibilidade.jpg";
import halitose from "../images/treatmentCards/halitose.jpg";
import dentesInclusos from "../images/treatmentCards/dentes-inclusos.jpg";
// import clareamento from "../images/treatmentCards/clareamento.jpg";
import traumaDentario from "../images/treatmentCards/trauma-dentario.jpg";
import bruxismo from "../images/treatmentCards/bruxismo.jpg";
import proteseDentaria from "../images/treatmentCards/protese-dentaria.jpg";
import facetasLentes from "../images/treatmentCards/facetas-e-lentes.jpg";
// import outrosTratamentos from "../images/treatmentCards/outros-tratamentos.jpg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import dotsDivider from "../images/icons/dot-divider.svg";

export default function Treatment() {
  const treatmentCards = [
    {
      title: "Cárie",
      subtitle: "Dor e inflamação",
      content: `A cárie é uma doença bucal causada pela ação de bactérias que produzem ácidos que desmineralizam o esmalte dentário. Se não tratada, pode evoluir e atingir a polpa do dente, causando dor e inflamação. O tratamento inclui a remoção do tecido cariado e a restauração do dente.`,
      img: caries,
      name: "caries",
    },
    {
      title: "Gengivite",
      subtitle: "Inflamação das gengivas",
      content: `A gengivite é uma inflamação das gengivas, geralmente causada pelo acúmulo de placa bacteriana. Os sintomas incluem gengivas vermelhas, inchadas e sangramento ao escovar os dentes. O tratamento envolve a remoção da placa e orientações sobre higiene bucal adequada.`,
      img: gengivite,
      name: "gengivite",
    },
    {
      title: "Periodontite",
      subtitle: "Gengivas Retraídas",
      content: `A periodontite é uma doença que afeta os tecidos de suporte dos dentes e pode levar à perda dentária. É uma evolução da gengivite e seus sintomas incluem gengivas retraídas, formação de bolsas periodontais e mobilidade dentária. O tratamento consiste na remoção do tártaro e controle da infecção.`,
      img: periodontite,
      name: "periodontite",
    },
    {
      title: "Sensibilidade",
      subtitle: "Dor e desconforto",
      content: `A sensibilidade dentária é caracterizada por dor ou desconforto ao consumir alimentos ou bebidas frias, quentes, doces ou ácidas. Pode ser causada por exposição da dentina, retração gengival ou desgaste do esmalte. O tratamento inclui o uso de produtos específicos e ajustes na alimentação e escovação.`,
      img: sensibilidade,
      name: "sensibilidade",
    },
    {
      title: "Halitose",
      subtitle: "Mau hálito",
      content: `A halitose, ou mau hálito, é uma condição caracterizada pelo odor desagradável na boca. Pode ser causada por má higiene bucal, doenças periodontais, xerostomia ou problemas sistêmicos. O tratamento envolve a identificação e resolução da causa subjacente.`,
      img: halitose,
      name: "halitose",
    },
    {
      title: "Dentes Inclusos",
      subtitle: "Infecções e danos",
      content: `Os dentes inclusos, comumente os terceiros molares ou "dentes do siso", são dentes que não conseguem emergir ou se desenvolver normalmente. A clínica realiza a extração destes dentes quando necessário, para prevenir complicações como infecções ou danos a outros dentes.`,
      img: dentesInclusos,
      name: "dentes-inclusos",
    },
    // {
    //   title: "Clareamento",
    //   subtitle: "Sorriso em destaque",
    //   content: `O clareamento dental é um processo estético que ilumina os dentes, removendo manchas e descolorações. Diversas opções de clareamento seguro e eficaz estão disponíveis para produzir um sorriso mais brilhante.`,
    //   img: clareamento,
    //   name: "clareamento",
    // },
    {
      title: "Traumas",
      subtitle: "Fratura, avulsão e luxação",
      content: `Traumas dentários são lesões que afetam os dentes e suas estruturas circundantes, geralmente causadas por impactos, quedas ou acidentes. Podem resultar em fraturas dentárias, avulsões (quando o dente é completamente deslocado do alvéolo) ou luxações (quando o dente é deslocado parcialmente).`,
      img: traumaDentario,
      name: "trauma-dentario",
    },
    {
      title: "Bruxismo",
      subtitle: "Desgaste Dentário",
      content: `O bruxismo é o hábito involuntário de ranger ou apertar os dentes, geralmente durante o sono. Pode causar desgaste dentário, dores na mandíbula e cefaleia. O tratamento pode incluir o uso de placas oclusais, terapia cognitivo-comportamental e técnicas de relaxamento.`,
      img: bruxismo,
      name: "bruxismo",
    },
    {
      title: "Protese dentária",
      subtitle: "Perda dos dentes",
      content: `A prótese dentária é um dispositivo utilizado para substituir o(s) dente(s) perdido(s). Elas podem ser removíveis (encaixadas à boca), ou fixas (implantadas). A escolha depende do tipo de tratamento mais indicado para o caso.`,
      img: proteseDentaria,
      name: "protese-dentaria",
    },
    {
      title: "Facetas e lentes",
      subtitle: "Irregularidades e manchas",
      content: `Geralmente são feitas em dentes anteriores e permitem mudar a cor, formato e tamanho dos dentes, de forma natural e harmoniosa. São indicadas quando há maior comprometimento, como em dentes que já possuem restaurações antigas, dentes muito manchados ou ligeiramente fora de posição.`,
      img: facetasLentes,
      name: "facetas-e-lentes",
    },
    // {
    //   title: "Outros tratamentos",
    //   subtitle: "Entre em contato!",
    //   content: `Caso apresente qualquer outra condição, não hesite em nos contatar. Nossa equipe altamente capacitada está pronta para fornecer um diagnóstico preciso e atender às suas necessidades!`,
    //   img: outrosTratamentos,
    //   name: "outros-tratamentos",
    // },
  ];

  return (
    <div id="lp-treatments" className="treatment-container">
      <img
        className="dots-divider"
        src={dotsDivider}
        alt="Divisor de conteúdo"
      />
      <h3 className="treatment-title">Procura algum tratamento?</h3>
      <p className="treatment-subtitle">
        Seu bem-star é a nossa prioridade. Fale agora mesmo com um de nossos
        especialistas!
      </p>
      <div className="treatment-slider">
        <Swiper
          spaceBetween={0}
          slidesPerView={"auto"}
          centeredSlides={true}
          autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
          loop={true}
          loopedSlides={4}
          initialSlide={3}
          modules={[Autoplay, Navigation]}
          breakpoints={
            {
              // 1919: {
              //   allowTouchMove: false,
              //   loop: false,
              //   slidesPerView: "auto",
              //   pagination: false,
              //   initialSlide: 2,
              // },
              // 1151: {
              //   slidesPerView: "auto",
              //   loop: false,
              //   centeredSlides: false,
              //   freeMode: true,
              // },
              // 767: {
              //   slidesPerView: 2,
              //   loop: true,
              //   centeredSlides: false,
              // },
              // 539: {},
            }
          }
        >
          {treatmentCards.map((card, index) => {
            return (
              <SwiperSlide key={index}>
                <TreatmentCard
                  key={index}
                  title={card.title}
                  subtitle={card.subtitle}
                  content={card.content}
                  img={card.img}
                  name={card.name}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}
