import React from "react";
import quote from "../../images/icons/format-quote.svg";
import whats from "../../images/icons/whats.svg";

import { whatsappLink } from "../../js/contact-info";

export default function Description({
  img,
  title,
  label,
  content,
  linkUrl,
  linkDesc,
}) {
  return (
    <div className="explain-container" style={{ marginTop: "-40px" }}>
      <div className="img-container-explain">
        <img
          src={img}
          alt="Imagem"
          loading="lazy"
          style={{
            borderRadius: "30px",
            boxShadow: "0px 10px 15px 0px rgba(77, 106, 146, 0.35)",
          }}
        />
      </div>
      <div className="content-container-explain">
        <img src={quote} alt="Quote" />
        <h1 className="title-explain">{title}</h1>
        <div className="label-explain">
          <h2>{label}</h2>
        </div>
        <p className="content-explain">
          {content}
          {linkUrl ? <a href={linkUrl}>{linkDesc}</a> : false}
        </p>
        <a
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
          className="homeCare-button-homeCare"
        >
          <p className="homeCare-button-white-whats">Agendar por Whatsapp</p>
          <img src={whats} alt="Whatsapp" />
        </a>
      </div>
    </div>
  );
}
