import React from "react";
import quot from "../../images/icons/format-quote.svg";
import whats from "../../images/icons/whats.svg";
import "../../styles/homeCare/careCard.css";

import careCard from "../../images/pictures/care-card-background.webp";

import { whatsappLink } from "../../js/contact-info";

import dotsDivider from "../../images/icons/dot-divider.svg";

export default function CareCard() {
  return (
    <>
      <img
        className="dots-divider"
        src={dotsDivider}
        alt="Divisor de conteúdo"
      />
      <div className="careCard-container">
        <div className="content-container-careCard">
          <div className="content-container-left-careCard">
            <img src={quot} alt="Quote" />
            <h3 className="title-careCard">
              Cuidados odontológicos para idosos e acamados
            </h3>
            <div className="label-careCard">
              <span>Home Care</span>
            </div>
            <p className="content-careCard">
              Essa modalidade de atendimento é especialmente benéfica para
              idosos e pessoas com doenças ou deficiências que dificultam as
              consultas em consultórios. É importante conhecer bem esse público
              e suas necessidades. 
            </p>
            <a
              href={whatsappLink}
              target="_blank"
              rel="noopener noreferrer"
              className="homeCare-button-homeCare"
            >
              <p className="homeCare-button-white-whats">
                Agendar por Whatsapp
              </p>
              <img src={whats} alt="Whatsapp" />
            </a>
          </div>
        </div>
        <div className="img-container-careCard">
          <img
            src={careCard}
            alt="Cuidados odontológicos para idosos"
            loading="lazy"
          />
        </div>
      </div>
    </>
  );
}
