import React from "react";
import SpecialtiesCard from "../components/SpecialtiesCard";

// import whiteTeeth from "../images/icons/white-teeth.svg";
import openMouth from "../images/icons/open-mouth.svg";
import screw from "../images/icons/screw.svg";
import prosthesis from "../images/icons/prosthesis.svg";
import mouth from "../images/icons/mouth.svg";
import teeth from "../images/icons/teeth.svg";

import dotsDivider from "../images/icons/dot-divider.svg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

export default function Specialties() {
  const cards = [
    {
      img: openMouth,
      title: "Endodontia",
      content:
        "A endodontia lida com doenças da polpa dentária e das raízes dos dentes. Tratamentos de canal são frequentemente realizados para preservar dentes comprometidos, proporcionando alívio da dor e prevenindo complicações futuras.",
    },
    {
      img: screw,
      title: "Implantodontia",
      content:
        "A implantodontia utiliza implantes metálicos que são implantados na maxila ou mandíbula para substituir raízes de dentes perdidos. Esses implantes servem de base para próteses dentárias, proporcionando uma solução estética e funcional.",
    },
    {
      img: prosthesis,
      title: "Próteses",
      content:
        "A prótese se concentra na restauração e substituição de dentes ausentes ou danificados tendo como objetivo restaurar a função e a estética dos dentes, proporcionando uma melhor qualidade de vida.",
    },
    {
      img: mouth,
      title: "Periodontia",
      content:
        "Periodontia é uma área focada na prevenção, diagnóstico e tratamento das doenças que afetam a gengiva e tecidos de suporte dos dentes. O tratamento tem como principais objetivos a restauração da estética e a função dos tecidos periodontais.",
    },
    {
      img: teeth,
      title: "Ortodontia",
      content:
        "A ortodontia é a área da odontologia que corrige o alinhamento e o posicionamento dos dentes e maxilares. Utiliza uma variedade de dispositivos, como aparelhos fixos e móveis, para alcançar um alinhamento ideal.",
    },
  ];

  return (
    <div id="lp-specialties" className="specialties-container">
      <img className="dots-divider" src={dotsDivider} alt="Divisor de conteúdo" />
      <div className="specialties-content">
        <h3 className="title-container">Especialidades</h3>
        {/* <p className='subtitle-container'>
        </p> */}
      </div>
      <div className="carousel-container">
        <Swiper
          slidesPerView={1}
          loop={true}
          centeredSlides={true}
          spaceBetween={0}
          autoplay={{ delay: 5000, pauseOnMouseEnter: true }}
          initialSlide={0}
          modules={[Autoplay, Navigation]}
          breakpoints={{
            1919: {
              slidesPerView: "auto",
              loop: false,
              pagination: false,
              allowTouchMove: false,
              initialSlide: 2,
              autoplay: false
            },
            1151: {
              slidesPerView: "auto",
              loop: false,
              pagination: false,
              centeredSlides: true,
              initialSlide: 2,
              freeMode: true,
              autoplay: false
            },
            767: {
              slidesPerView: 2,
              loop: true,
              centeredSlides: false,
            },
            539: {},
          }}
        >
          {cards.map((card, index) => {
            return (
              <SwiperSlide key={index}>
                <SpecialtiesCard
                  key={index}
                  img={card.img}
                  title={card.title}
                  content={card.content}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}
