import React, { useState } from "react";
import showIconWhite from "../images/icons/show-icon-white.svg";
import { useNavigate } from "react-router-dom";

export default function TreatmentCard({ title, subtitle, content, img, name }) {
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const cardStyle = {
    position: "relative",
    overflow: "hidden",
    background: `url(${img}), lightgray 19.5px 304.601px / 79.051% 33.07% no-repeat`,
    backgroundBlendMode: "normal, color, normal",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  };

  const overlayStyle = {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    pointerEvents: "none",
  };

  const contentStyle = {
    position: "relative",
    zIndex: 1,
  };

  return (
    <div
      className={`treatment-card-container ${isHovered ? "grow" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={cardStyle}
    >
      <div className="treatment-card-content" style={contentStyle}>
        {!isHovered ? (
          <div className="treatment-card-hidden">
            <div className="treatment-card-container-title-hidden">
              <h3 className="treatment-card-title-hidden">{title}</h3>
              <img src={showIconWhite} alt="showIconWhite" />
            </div>
            <p className="treatment-card-subtitle-hidden">{subtitle}</p>
          </div>
        ) : (
          <div className="treatment-card-show">
            <h4 className="treatment-card-title-show">{title}</h4>
            <p className="treatment-card-subtitle-show">{subtitle}</p>
            <p className="treatment-card-content-show">{content}</p>
            <button
              className="treatment-card-button-show"
              onClick={() => navigate(`../treatment/${name}`)}
            >
              <p className="treatment-card-button-name-show">Saiba mais</p>
            </button>
          </div>
        )}
      </div>
      {isHovered && (
        <div className="treatment-card-overlay" style={overlayStyle}></div>
      )}
    </div>
  );
}
