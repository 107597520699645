import React from "react";
import formatQuote from "../images/icons/format-quote.svg";
import whats from "../images/icons/whats.svg";
import "../styles/HomeCare.css";
import { useNavigate } from "react-router-dom";

import { whatsappLink } from "../js/contact-info";

import dotsDivider from "../images/icons/dot-divider.svg";

export default function HomeCare() {
  const navigate = useNavigate();

  return (
    <>
      <img
        className="dots-divider"
        src={dotsDivider}
        alt="Divisor de conteúdo"
      />
      <div id="lp-homecare" className="homeCare-container">
        <div className="card-homeCare-img">
          <p className="homeCare-img-subtitle">SUA FAMÍLIA EM BOAS MÃOS</p>
          <h4 className="homeCare-img-title">Atendimento domiciliar</h4>
          <button
            className="homeCare-img-button"
            onClick={() => navigate("/home-care")}
          >
            <p className="homeCare-img-button-content">Saiba Mais</p>
          </button>
        </div>
        <div className="card-homeCare-button">
          <div>
            <img src={formatQuote} alt="Quote" />
            <h3 className="homeCare-button-title">
              Atendimento com o cuidado e a atenção que só uma família pode
              oferecer.
            </h3>
          </div>
          <span className="homeCare-button-subtitle">
            Clique para saber mais!
          </span>
          <a
            href={whatsappLink}
            target="_blank"
            rel="noopener noreferrer"
            className="homeCare-button-homeCare"
          >
            <p className="homeCare-button-white-whats">Agendar por Whatsapp</p>
            <img src={whats} alt="whats" loading="lazy" />
          </a>
        </div>
      </div>
    </>
  );
}
