import React from "react";
import like from "../images/icons/like.svg";
import star from "../images/icons/star.svg";
import whats from "../images/icons/whats.svg";
import blackQuote from "../images/icons/format-quote-black.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { whatsappLink } from "../js/contact-info";

export default function ReviewCard({ img, name, where, reviews }) {
  const settingsReview = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    vertical: false,
    nextArrow: null,
    prevArrow: null,
  };

  return (
    <div className="container-maincard-review-wrapper">
      <div className="container-maincard-review">
        <div className="container-card-review">
          <div className="container-card-review-left">
            <img
              className="card-review-img"
              src={img}
              alt={name}
              loading="lazy"
            />
            <h3 className="card-review-name">{name}</h3>
            <div className="card-review-subtitle">
              <p>Satisfação total dos clientes</p>
              <img src={like} alt="like" />
            </div>
            <a
              href={whatsappLink}
              target="_blank"
              rel="noopener noreferrer"
              className="card-review-button"
            >
              <p className="card-review-button-name">Agendar por Whatsapp</p>
              <img src={whats} alt="whats" />
            </a>
            <div className="rate-container">
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
            </div>
            <p className="rate-description-black">{where}</p>
          </div>
          <div className="container-card-review-right">
            <img src={blackQuote} alt="Quote" />
            <Slider {...settingsReview}>
              {reviews.map((text, index) => (
                <div key={index}>
                  <div className="teste">
                    <h4 className="card-review-content">{text.review}</h4>
                    <h5 className="card-review-content-name">{text.author}</h5>
                    <p className="card-review-content-status">{text.status}</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
