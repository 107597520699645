import React from 'react';
import add from '../../images/icons/add.svg';
import '../../styles/homeCare/sectionParagraph.css';

export default function SectionParagraph() {
  return (
    <div className='sectionParagraph-container'>
      <div className='sectionParagraph-subcontainer'>
        <img src={add} alt="Add" />
        <div>
          <h4 className='sectionParagraph-title'>
            Atendimento domiciliar
          </h4>
          <div className='sectionParagraph-content'>
            <p>
              A odontologia domiciliar, também conhecida pelo termo Home Care, abrange o atendimento odontológico a pacientes com dificuldades de locomoção e deslocamento, por diversas razões, e, aqueles que requerem cuidados específicos devido a doenças e síndromes. É uma área multidisciplinar que visa promover qualidade de vida, porém, ainda há poucos profissionais capacitados nesse campo de atuação.
            </p>
            <p>
              Os cirurgiões-dentistas que optam por trabalhar nesse segmento devem assumir responsabilidades maiores, adaptando-se a diferentes ambientes e equipes de trabalho, portanto devem possuir conhecimentos além da prática odontológica, como questões sociais, econômicas e psicológicas.
            </p>
          </div>
        </div>
      </div>
      <div className='sectionParagraph-subcontainer'>
        <img src={add} alt="Add" />
        <div>
          <h4 className='sectionParagraph-title'>
            Características
          </h4>
          <div className='sectionParagraph-content'>
            <p>
              O atendimento odontológico domiciliar segue um processo semelhante a uma consulta convencional, porém com a vantagem de o dentista se deslocar até a residência do paciente. O objetivo é proporcionar comodidade, levando os materiais necessários para realizar o tratamento de forma adequada. 
            </p>
            <p>
              É fundamental ressaltar que cada situação requer uma avaliação individualizada para determinar a melhor abordagem de tratamento. Os profissionais de saúde, em conjunto com a equipe odontológica, podem avaliar as necessidades específicas de cada paciente e fornecer os cuidados apropriados para garantir sua saúde bucal em seu ambiente de preferência.
            </p>
          </div>
        </div>
      </div>
      <div className='sectionParagraph-subcontainer'>
        <img src={add} alt="Add" />
        <div>
          <h4 className='sectionParagraph-title'>
            Idosos
          </h4>
          <div className='sectionParagraph-content'>
            <p>
              Segundo a Lei 10.741, os idosos têm o direito ao atendimento domiciliar, inclusive em instituições públicas, filantrópicas e áreas rurais. Para idosos com deficiência ou limitações, é necessário um atendimento especializado.
            </p>
            <p>
              O atendimento domiciliar em odontologia fortalece a confiança no dentista, promove o bem-estar do paciente e humaniza a consulta, garantindo conforto e respeitando a integridade dos idosos com dificuldades físicas para se deslocar ao consultório ou que não possuem independência suficiente para receber atendimento sozinhos.
            </p>
            <p>
              É essencial compreender as diferentes necessidades dos idosos, que podem ser divididos em dois grupos, dependentes e parcialmente dependentes. A principal abordagem desenvolve planos de tratamento multidisciplinares, que envolvem treinamento e colaboração dos familiares, que desempenham um papel fundamental para a manutenção da saúde dos pacientes.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
