import React from "react";
import addCircule from "../../images/icons/add-circle-outline.svg";
import "../../styles/homeCare/serviceModality.css";

import dotsDivider from "../../images/icons/dot-divider.svg";

export default function ServiceModality() {
  return (
    <div className="modality-container">
      <img className="dots-divider" src={dotsDivider} alt="Divisor de conteúdo" />
      <h3 className="modality-title">
        Modalidade de atendimento indicada para:
      </h3>
      <div className="modality-subcontainer">
        <div className="modality-content-container">
          <img src={addCircule} alt="add circule" />
          <div className="modality-content">
            <h4 className="modality-content-title">
              Idosos com dificuldades de locomoção:
            </h4>
            <p className="modality-content-p">
              Pacientes idosos que enfrentam limitações físicas devido à idade
              avançada, fraqueza muscular, problemas nas articulações ou outras
              condições que comprometem sua capacidade de se deslocar para um
              consultório odontológico.
            </p>
          </div>
        </div>
        <div className="modality-content-container">
          <img src={addCircule} alt="add circule" />
          <div className="modality-content">
            <h4 className="modality-content-title">
              Pessoas com doenças crônicas:
            </h4>
            <p className="modality-content-p">
              Indivíduos que sofrem de condições médicas crônicas, como doenças
              cardíacas, diabetes, artrite reumatoide, doença de Parkinson,
              entre outras, que podem afetar a mobilidade e tornar difícil a ida
              a um consultório odontológico.
            </p>
          </div>
        </div>
        <div className="modality-content-container">
          <img src={addCircule} alt="add circule" />
          <div className="modality-content">
            <h4 className="modality-content-title">
              Pacientes em recuperação pós-cirúrgica:
            </h4>
            <p className="modality-content-p">
              Indivíduos que estão se recuperando de cirurgias que exigem
              repouso em casa ou restrições de mobilidade, como cirurgias
              ortopédicas, cardíacas ou abdominais, e que necessitam de cuidados
              odontológicos durante esse período de recuperação.
            </p>
          </div>
        </div>
        <div className="modality-content-container">
          <img src={addCircule} alt="add circule" />
          <div className="modality-content">
            <h4 className="modality-content-title">
              Pacientes acamados ou em cuidados paliativos:
            </h4>
            <p className="modality-content-p">
              Indivíduos que estão confinados à cama ou recebendo cuidados
              paliativos devido a doenças graves, terminais ou debilitantes, que
              precisam de atendimento odontológico adaptado para manter sua
              saúde bucal durante essa fase.
            </p>
          </div>
        </div>
        <div className="modality-content-container">
          <img src={addCircule} alt="add circule" />
          <div className="modality-content">
            <h4 className="modality-content-title">
              Pessoas com ansiedade ou fobia dentária severa:
            </h4>
            <p className="modality-content-p">
              Pacientes que sofrem de ansiedade extrema ou fobia dentária que
              limitam sua capacidade de buscar tratamento odontológico em um
              ambiente convencional, necessitando de abordagens personalizadas
              para lidar com suas preocupações e receber os cuidados
              necessários.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
