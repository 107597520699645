import React from "react";
import "../styles/Specialties.css";

export default function SpecialtiesCard({ img, title, content }) {
  return (
    <div className="card-container">
      <img src={img} alt={title} loading="lazy" />
      <h4 className="title-specialties">{title}</h4>
      <p className="content-specialties">{content}</p>
      {/* <button className='button-specialties'>
        <p1 className='name-button-specialties'>
          Saiba mais
        </p1>
      </button> */}
    </div>
  );
}
