import React, { useEffect } from "react";
import "../styles/NotFoundPage.css";

import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="not-found-page">
      <Helmet>
        <title>Página não encontrada</title>
        <meta name="description" content="Página não encontrada" />
      </Helmet>
      <h1>404 - Página não encontrada</h1>
      <p>Desculpe, a página que você está procurando não foi encontrada.</p>
    </div>
  );
};

export default NotFoundPage;
