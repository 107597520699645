import React, { useEffect } from "react";
import Banner from "../components/Banner";
import "../styles/Banner.css";
import Information from "../components/Information";
import Specialties from "../components/Specialties";
import HomeCare from "../components/HomeCare";
import Dentists from "../components/Dentists";
import Client from "../components/Client";
import Treatment from "../components/Treatment";
import Family from "../components/Family";
import Location from "../components/Location";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

import imgDefaultBackground from "../images/pictures/woman-smile-picture.webp";
import imgHomeCareBackground from "../images/pictures/home-care-banner.webp";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Helmet } from "react-helmet";

export default function LandinPage() {
  useEffect(() => {
    window.scrollTo(0, 0);

    const hash = window.location.hash ? window.location.hash : false;
    if (hash) {
      const element = document.getElementById(hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const banners = [
    {
      subtitle: "ATENDIMENTO ESPECIALIZADO",
      title: "Agende agora sua consulta",
      img: imgDefaultBackground,
    },
    {
      subtitle: "SUA FAMÍLIA EM BOAS MÃOS",
      title: "Atendimento Domiciliar",
      img: imgHomeCareBackground,
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Tsukamoto Odontologia</title>
        <meta name="description" content="Tsukamoto Clínica Odontológica" />
      </Helmet>
      <div className="background-header">
        <div className="banner-container">
          <Swiper
            slidesPerView={1}
            loop={true}
            centeredSlides={true}
            spaceBetween={0}
            autoplay={{ delay: 10000, pauseOnMouseEnter: true }}
            initialSlide={0}
            modules={[Autoplay, Navigation]}
            // navigation={true}
          >
            {banners.map((banner, index) => {
              return (
                <SwiperSlide key={index}>
                  <Banner
                    subtitle={banner.subtitle}
                    title={banner.title}
                    img={banner.img}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      <Information />
      <Specialties />
      <HomeCare />
      <Dentists />
      <Client />
      <Treatment />
      <Family />
      <Location />
      <Contact />
      <Footer />
    </div>
  );
}
