import React from "react";
import quote from "../../images/icons/format-quote.svg";
import "../../styles/homeCare/phrase.css";

export default function Phrase() {
  return (
    <div className="phrase-container">
      <div className="left-container">
        <img src={quote} alt="Quote" />
        <div className="title-container-phrase">
          <h3 className="title-phrase">
            Atendimento com o cuidado e a atenção que só uma família pode
            oferecer.
          </h3>
        </div>
      </div>
      <div className="right-container">
        <p className="content-phrase">
          Nossa equipe está totalmente comprometida em fornecer auxílio e
          garantir que seu ente querido tenha acesso aos cuidados odontológicos
          necessários para desfrutar de refeições com conforto e livres de dor.
          E, acima de tudo, sorrir!
        </p>
      </div>
    </div>
  );
}
