import React from "react";
import MapContainer from "./MapContainer";
import "../styles/Location.css";

import {
  // phoneText,
  whatsappText,
} from "../js/contact-info";

import dotsDivider from "../images/icons/dot-divider.svg";

export default function Location() {
  return (
    <div id="lp-location" className="location-container">
      <img className="dots-divider dots-divider__white" src={dotsDivider} alt="Divisor de conteúdo" />
      <div>
        <h3 className="location-title">Localização</h3>
        <p className="location-subtitle">Sua família em boas mãos.</p>
      </div>
      <div className="location-content-container">
        <div className="location-content">
          <div>
            <p className="location-content-comenttitle">Omoiyari no aru hito</p>
            <h4 className="location-content-title">思いやりのある人</h4>
            <p className="location-content-subtitle">
              Pessoa com consideração e empatia
            </p>
          </div>
          <h5 className="location-content-phrase">
            “Príncipio da empatia: ter consideração pelo próximo”
          </h5>
          <p className="location-content-maincontent">
            A cultura japonesa tem um profundo senso de gratidão, e a clínica
            Tsukamoto expressa essa gratidão por seus pacientes através do
            Omoiyari no aru hito. Somos verdadeiramente gratos pela confiança
            que nossos pacientes nos depositam e, pela oportunidade tê-los sob
            nosso cuidado. Ao incorporar essa filosofia em nossa aproximação com
            os pacientes, nos esforçamos diariamente para criar um ambiente
            acolhedor e compassivo para todos que entram em nossas portas.
          </p>
        </div>
        <div className="location-map">
          <MapContainer />
        </div>
      </div>
      <div className="contacts">
        <div>
          <p>Atendimento</p>
          <div className="contacts-content">
            <p>Idosos, Adultos, Crianças (+6 anos)</p>
            <p>De Segunda a sexta, das 8h às 18h e sábado das 8h às 12h</p>
          </div>
        </div>
        <div>
          <p>Contato</p>
          <div className="contacts-content">
            {/* <p>
              {phoneText}
            </p> */}
            <p>{whatsappText}</p>
          </div>
        </div>
        <div>
          <p>Endereço</p>
          <div className="contacts-content">
            <p>Rua Maestro Carlos Frank 1960, sala 16</p>
            <p>Boqueirão, Curitiba</p>
          </div>
        </div>
      </div>
    </div>
  );
}
